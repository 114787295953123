import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import RefundDialog from '../Dialogs/RefundDialog';
import * as Ant from 'antd';
import {navigate} from 'gatsby';
import {errorHandler} from '../errors';
import {useOutlet} from 'reconnect.js';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Anchor from '../Widgets/Anchor';
import {REFUND_TYPES, REFUND_STATUS} from '../dictionary';
import {getProfileDisplay} from '../Utils/getProfileDisplay';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function RefundList(props) {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [filters, setFilters] = useState({
    status: 'all',
    ...PAGINATION_INIT,
  });
  const [total, setTotal] = useState(0);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [actions] = useOutlet('actions');

  const getRecords = useCallback(async () => {
    let {status, current, pageSize} = filters;

    actions.setLoading(true);
    try {
      let resp = await actions.getRefunds({
        ordering: '-created',
        offset: (current - 1) * pageSize,
        limit: pageSize,
        ...(status !== 'all' && {status}),
      });
      setRecords(resp.results);
      setTotal(resp.count);
    } catch (ex) {
      errorHandler(ex, '取得退款單列表錯誤');
    }
    actions.setLoading(false);
  }, [actions, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4 className="short">退款狀態</h4>
            <Ant.Select
              value={filters.status}
              onChange={(nextValue) =>
                setFilters((prev) => ({
                  ...prev,
                  status: nextValue,
                  ...PAGINATION_INIT,
                }))
              }>
              {Object.keys(REFUND_STATUS)
                .map((key) => ({
                  label: REFUND_STATUS[key],
                  value: key,
                }))
                .map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
            </Ant.Select>
            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getRecords}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </div>
      </Block>
      <Block>
        <h2>{REFUND_STATUS[filters.status]}</h2>
        <div>
          <Ant.Table
            columns={[
              {
                title: '退款單編號',
                render: (_, instance) => (
                  <Anchor
                    type="button"
                    onClick={() => {
                      setSelectedRecord(instance);
                      setOpenDetailDialog(true);
                    }}>
                    # {instance.id}
                  </Anchor>
                ),
              },
              {
                title: '訂單編號',
                render: (_, instance) => (
                  <Anchor to={`/order/?id=${instance.order}`}>
                    {instance.display_id}
                  </Anchor>
                ),
              },
              {
                title: '退款方式',
                render: (_, record) => REFUND_TYPES[record.refund_type],
              },
              {
                title: '退款單狀態',
                render: (_, record) => REFUND_STATUS[record.status],
              },
              {
                title: '經辦人',
                render: (_, record) => getProfileDisplay(record.issuer),
              },
              {
                title: '顧客名稱',
                render: (_, record) => getProfileDisplay(record.order_owner),
              },
              {
                title: '建立時間',
                render: (_, order) =>
                  ` ${order.created.slice(0, 10)} ${order.created.slice(
                    11,
                    16,
                  )}`,
              },
              {
                title: '備註',
                dataIndex: 'note',
              },
            ]}
            dataSource={records}
            rowKey={(item) => item.id}
            pagination={{
              pageSize: filters.pageSize,
              current: filters.current,
              total,
            }}
            onChange={(pagination) =>
              setFilters((prev) => ({
                ...prev,
                pageSize: pagination.pageSize,
                current: pagination.current,
              }))
            }
          />
        </div>
      </Block>

      {openDetailDialog && (
        <RefundDialog
          record={selectedRecord}
          onUpdate={getRecords}
          closeDialog={() => setOpenDetailDialog(false)}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
